import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/malithia.github.io/malithia.github.io/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AuthorBanner = makeShortcode("AuthorBanner");
const Link = makeShortcode("Link");
const Button = makeShortcode("Button");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <h4 {...{
      "id": "professional-writing"
    }}>{`Professional writing`}</h4>
    <p style={{
      "fontSize": "18px"
    }}>Samples from professional writing assignments.</p>
    <h6>Blog articles&nbsp;&nbsp;
  <Link to='blogs' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">content writing</Button>
  </Link>
    </h6>
    <FeedItems itemsFormat="compact" filterByTags={['blog', 'article']} limit={4} mdxType="FeedItems" />
    <h6>Use cases&nbsp;&nbsp;
  <Link to='use-case' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">content writing</Button>
  </Link>
    </h6>
    <FeedItems itemsFormat="compact" filterByTags={['use-case']} limit={4} mdxType="FeedItems" />
    <h6>Whitepapers&nbsp;&nbsp;
  <Link to='whitepapers' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">content writing</Button>
  </Link>
    </h6>
    <FeedItems itemsFormat="compact" filterByTags={['whitepaper']} limit={4} mdxType="FeedItems" />
    <h6>Documentation&nbsp;&nbsp;
  <Link to='documentation' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">documentation</Button>
  </Link>
    </h6>
    <FeedItems itemsFormat="compact" filterByTags={['documentation']} limit={4} mdxType="FeedItems" />
    <h4 {...{
      "id": "personal-writing"
    }}>{`Personal writing`}</h4>
    <p style={{
      "fontSize": "18px"
    }}>Personal writing, including poetry and academic writing.</p>
    <h6>Academic publications&nbsp;&nbsp;
  <Link to='academic-writing' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">publications</Button>
  </Link>
    </h6>
    <FeedItems itemsFormat="compact" filterByTags={['publication']} limit={4} mdxType="FeedItems" />
    <h6>Poems&nbsp;&nbsp;
  <Link to='poems' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">poetry</Button>
  </Link>
    </h6>
    <FeedItems itemsFormat="compact" filterByTags={['poem']} limit={4} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      